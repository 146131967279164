/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
// eslint-disable-next-line linebreak-style
import {
  useState, useEffect, lazy, Suspense,
} from 'react';
import PropTypes from 'prop-types';
import key from 'weak-key';
import HeaderErrImg from './Assets/images/chilis_photo_collage_r2.jpg';
import Loading from './Loading';
import './Style-main.css';

// lazy load slices
const Hero = lazy(() => import('./Slices/Hero'));
const AccordionSlice = lazy(() => import('./Slices/AccordionSlice'));
const ImageGallery = lazy(() => import('./Slices/ImageGallery'));
const CarouselSlice = lazy(() => import('./Slices/CarouselSlice'));
// const CarouselSliceType4 = lazy(() => import('./Slices/CarouselSliceType4'));
const CarouselSliceType2 = lazy(() => import('./Slices/CarouselSliceType2'));
const CarouselSliceType3 = lazy(() => import('./Slices/CarouselSliceType3'));
const FeatureItem = lazy(() => import('./Slices/FeatureItem'));
const FeatureItemsStackType2 = lazy(() => import('./Slices/FeatureItemsStackType2'));
const FeatureItemsStackHorizontal = lazy(() => import('./Slices/FeatureItemsStackHorizontal'));
const FeatureItemsStackType3 = lazy(() => import('./Slices/FeatureItemsStackType3'));
const FeatureItemsStack = lazy(() => import('./Slices/FeatureItemsStack'));
const Notice = lazy(() => import('./Slices/Notice'));
const TextBlock = lazy(() => import('./Slices/TextBlock'));
const TableSlice = lazy(() => import('./Slices/TableSlice'));
const YellowHeader = lazy(() => import('./Slices/YellowHeader'));
const NavbarPage = lazy(() => import('./Components/Header/NavbarPage'));
const SocialNewsLetter = lazy(() => import('./Components/Footer/SocialNewsLetter'));
const Footer = lazy(() => import('./Components/Footer/Footer'));
const CustomHeader = lazy(() => import('./Slices/CustomHeader'));
const CustomTitleSubTitle = lazy(() => import('./Slices/CustomTitleSubTitle'));
const Contact = lazy(() => import('./Slices/Contact'));
function Main(props) {
  const [items, setItems] = useState([]);
  const [header, setHeader] = useState('normal');
  // Only transparent when above scroll when there is a hero header
  const [solid, setSolid] = useState({ scrollSolid: false, heroSolid: true });
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');
  const { uid } = props;
  const isSolid = (data) => {
    setSolid((oldSolid) => ({ ...oldSolid, scrollSolid: data }));
  };
  useEffect(() => {
    const getPrismicData = (abortController) => {
      fetch(`/brinkercom/main/${uid}`, {
        signal: abortController.signal,
      })
        .then((res) => res.json())
        .then((data) => {
          const contents = [];
          const { document: { data: { body: sliceArray } } } = data;
          sliceArray.forEach((slice) => {
            const { slice_type: sliceType } = slice;
            switch (sliceType) {
              case 'hero':
                setSolid((oldSolid) => ({ ...oldSolid, heroSolid: false }));
                contents.push(
                  <Hero key={key(slice)} isSolid={isSolid} slice={slice} />,
                );
                break;
              case 'image_gallery': {
                const { slice_label: sliceLabel } = slice;
                contents.push(
                  <ImageGallery key={key(slice)} pagination={sliceLabel === 'true'} slice={slice} />,
                );
                break;
              }
              case 'accordion':
                contents.push(<AccordionSlice key={key(slice)} slice={slice} />);
                break;
              case 'carousel':
                contents.push(<CarouselSlice key={key(slice)} slice={slice} />);
                break;
               case 'carousel_type_2':
                  contents.push(<CarouselSliceType2 key={key(slice)} slice={slice} />);
                  break;
              case 'carousel_type_3':
                contents.push(<CarouselSliceType3 key={key(slice)} slice={slice} />);
                break;
              case 'custom_header':
                contents.push(<CustomHeader key={key(slice)} slice={slice} />);
                break;
              case 'custom_title_subtitle_richtext':
                contents.push(<CustomTitleSubTitle key={key(slice)} slice={slice} />);
                break;
              case 'featured_item':
                contents.push(<FeatureItem key={key(slice)} slice={slice} />);
                break;
              case 'featured_item_type2':
                contents.push(<FeatureItemsStackType2 key={key(slice)} slice={slice} />);
                break;
              case 'featured_items_stack_type2':
                  contents.push(<FeatureItemsStackHorizontal key={key(slice)} slice={slice} />);
                  break;
              case 'featured_item_type_3':
                contents.push(<FeatureItemsStackType3 key={key(slice)} slice={slice} />);
                break;
              case 'featured_items_stack':
                contents.push(<FeatureItemsStack key={key(slice)} slice={slice} />);
                break;
              case 'notice':
                contents.push(<Notice key={key(slice)} slice={slice} />);
                break;
              case 'footer_documents':
                contents.push(<TextBlock key={key(slice)} slice={slice} />);
                break;
              case 'table':
                contents.push(<TableSlice key={key(slice)} slice={slice} />);
                break;
              case 'yellow_header':
                contents.push(<YellowHeader key={key(slice)} className="mt-2" slice={slice} />);
                break;
              case 'contact_right':
                contents.push(<Contact key={key(slice)} slice={slice} />);
                break;
              default:
                break;
            }
          });
          setItems(contents);
          setLoaded(true);
        }, (e) => { setError(e); });
    };
    const getHeader = () => {
      if (window.innerWidth >= 1200) {
        setHeader('normal');
      } else {
        setHeader('mobile');
      }
    };
    const abortController = new AbortController();
    window.addEventListener('resize', getHeader);
    getPrismicData(abortController);
    return () => {
      window.removeEventListener('resize', getHeader);
      abortController.abort();
    };
  }, [uid]);
  if (error) {
    return (
      <div className="company-page-content">
        <div className="nav-style">
          <div
            className={
              solid.scrollSolid || solid.heroSolid
                ? 'sticky fixed-nav'
                : ''
          }
          >
            <NavbarPage />
          </div>
        </div>
        <section
          className="chilis hero bg-error-image"
          style={{
            backgroundImage: `url(${HeaderErrImg})`,
          }}
        />
        <div className="jumbotron">
          <div className="container text-center">
            <h3 style={{ color: 'red' }}>Failed to retrieve data</h3>
          </div>
        </div>

        <SocialNewsLetter />
        <Footer />
      </div>
    );
  }
  if (loaded) {
    return (
      <Suspense fallback={<Loading />}>
        <div className="company-page-content">
          <div className="nav-style">
            <div className={solid.scrollSolid || solid.heroSolid ? ' sticky fixed-nav' : ''}>
              <NavbarPage transparent="transparent" header={header} />
            </div>
          </div>
          <div
            className="sliceContent"
          >
            {items}
            <div>
              <SocialNewsLetter />
              <Footer />
            </div>
          </div>
        </div>
      </Suspense>
    );
  }
  return (<Loading />);
}
Main.defaultProps = {
  uid: '',
};
Main.propTypes = {
  uid: PropTypes.string,
};
export default Main;
